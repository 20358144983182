<template>
  <v-container id="create" class="general">
    <page-title :title="'Messages'">
      <template slot="actions">
        <LanguageSwitcher :all-languages="true" :loading="isLoading" />
      </template>
    </page-title>
    <transition name="fade" mode="out-in">
      <v-layout
        v-if="!isLoading && !messages.length"
        class="row wrap text-center"
      >
        <v-flex class="xs12 sw-h5 font-weight-light">
          {{ $t("message_template_list_page.nothing_created") }}
        </v-flex>
      </v-layout>
    </transition>
    <template v-if="messages && messages.length">
      <v-layout
        v-for="(message, i) in messages"
        :key="`message-${i}`"
        class="row wrap px-4"
      >
        <v-flex xs12 py-4>
          <v-layout class="row no-wrap align-center">
            <font-awesome-icon
              :icon="messageTypeIcon(message.type)"
              class="grey--text"
              style="font-size: 29px"
            ></font-awesome-icon>
            <v-flex class="xs12 pl-4 text-ellipsis">
              <a
                class="sw-h5 font-weight-light"
                @click.stop="editMessageTemplate(message.id)"
                >{{ message.name }}</a
              >
            </v-flex>
            <v-btn
              icon
              class="ma-0"
              :loading="isLoading"
              @click="deleteMessageTemplate(message.id, message.name)"
            >
              <font-awesome-icon class="sw-accent" icon="trash" />
            </v-btn>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-divider></v-divider>
        </v-flex>
      </v-layout>
    </template>
    <div v-if="totalPages > 1" class="text-xs-center mt-4">
      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="totalVisible"
        @input="listMessageTemplates"
      ></v-pagination>
    </div>
    <app-speed-dial
      v-model="fab"
      :hint="$t('message_template_list_page.speed_dial_hint')"
    >
      <template slot="items">
        <div
          v-if="messageTypes && messageTypes.length"
          class="px-3 py-2 speed-dial-items"
        >
          <div
            v-for="(type, i) in messageTypes"
            :key="`type-${i}`"
            class="py-2 text-right caption speed-dial-item"
          >
            <span class="mr-3 item-title">{{
              $t(`message_template_list_page.new_${type}_message`)
            }}</span>
            <v-avatar
              size="36"
              color="white"
              class="elevation-2 cursor-pointer"
              @click="createMessageTemplate(type)"
            >
              <font-awesome-icon
                :icon="messageTypeIcon(type)"
                style="font-size: 19px"
                class="sw-accent"
              />
            </v-avatar>
          </div>
        </div>
      </template>
    </app-speed-dial>
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  components: {
    LanguageSwitcher,
  },
  data: () => ({
    isLoading: false,
    fab: false,
    messages: [],
    page: 1,
    totalPages: 0,
    totalVisible: 5,
    perPage: 16,
  }),
  computed: {
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    currentPage() {
      if (this.$route.query.page) {
        return Number(this.$route.query.page);
      }
      return 1;
    },
    messageTypes() {
      return ["email", "sms", "push"];
    },
  },
  watch: {
    "$route.query.page": {
      handler(to, from) {
        if (!to || !from) {
          return;
        }
        if (Number(to) !== Number(from)) {
          this.page = this.currentPage;
          this.listMessageTemplates();
        }
      },
    },
    appContentLanguage: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;
        this.listMessageTemplates();
      },
    },
  },
  mounted() {
    this.page = this.currentPage;
    this.listMessageTemplates();
  },
  methods: {
    messageTypeIcon(type) {
      if (type === "email") return "envelope";
      if (type === "sms") return "sms";
      if (type === "push") return "mobile";
    },
    createMessageTemplate(type) {
      this.$router.push(
        { name: "message_templates_create", query: { type: type } },
        () => {},
      );
    },
    editMessageTemplate(id) {
      this.$router.push(
        {
          name: "message_templates_edit",
          params: {
            message_id: id,
          },
        },
        () => {},
      );
    },
    query() {
      this.$router.replace(
        {
          query: Object.assign({}, this.$route.query, { page: this.page }),
        },
        () => {},
      );
    },
    async listMessageTemplates() {
      try {
        const params = {
          lang: this.appContentLanguage,
          page: this.page,
          per_page: this.perPage,
        };

        this.isLoading = true;

        const response = await this.$api.messageTemplates.list(params);

        this.isLoading = false;

        this.messages = response.data.data;
        this.totalPages = response.data.pagination.total_pages;

        this.query();
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }
    },
    async deleteMessageTemplate(id, title) {
      const isConfirmed = await this.$refs.confirmationDialog.open(
        this.$t("message_template_list_page.message_template_delete_warning", {
          name: title,
        }),
      );

      if (!isConfirmed) return;

      try {
        this.isLoading = true;

        await this.$api.messageTemplates.delete(id);

        this.isLoading = false;

        this.$store.dispatch("addNotification", {
          message: `Message ${title} deleted`,
        });

        this.page = 1;
        this.listMessageTemplates();
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.speed-dial-items {
  border: 1px solid $accent;
  background: #fff;
  margin-right: -16px;
  margin-bottom: 16px;
  border-radius: 8px;

  .speed-dial-item {
    white-space: nowrap;

    .item-title {
      white-space: nowrap;
    }
  }
}
</style>
